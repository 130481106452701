import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

// CSS
import 'styles/HomePage.scss'

// Components
import { BannerDefault } from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import ContactForm from '../components/ContactForm'

// Images
import arrow from '../img/arrow-menu.svg'
import arrowWhite from '../img/arrow-white.svg'
import jeroen from '../img/jeroen.png'

function HomePage({ data }) {
  const { wordpressPage: page } = data
  const homeHeader = page.acf.header
  const watIs = page.acf.home_wat_is_kavelruil
  const orderBook = page.acf.order_book
  const homeAuthor = page.acf.about_author

  return (
    <Layout>
      <SEO yoast={{ meta: page.yoast_meta }} />
      <div className="home-page">
        <BannerDefault className="py-5">
          <div className="row">
            <div className="col-lg-6 text">
              <h1>
                <div
                  className="first"
                  dangerouslySetInnerHTML={{
                    __html: homeHeader.header_text_first,
                  }}
                />
                <div
                  className="last"
                  dangerouslySetInnerHTML={{
                    __html: homeHeader.header_text_last,
                  }}
                />
              </h1>
            </div>
          </div>
        </BannerDefault>
        <section className="home-intro">
          <div className="container">
            <div className="row py-4 align-items-center">
              <div
                className="col-lg-7"
                dangerouslySetInnerHTML={{ __html: homeHeader.subheader_text }}
              />
              <div className="col-lg-5">
                <Link to={homeHeader.subheader_button_link} className="btn">
                  {homeHeader.subheader_button_text}
                  <img
                    src={arrow}
                    alt="arrow"
                    width="18"
                    className="button-img"
                  />
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="wat-is-kavelruil">
          <div className="container">
            <div className="row">
              <div className="col-12 empty-space-100" />
              <div className="col-lg-7">
                <h2 className="title">
                  <img src={arrow} width="18" alt="arrow" />
                  {watIs.title}
                </h2>
                <div dangerouslySetInnerHTML={{ __html: watIs.text }} />
                <p className="float-right">
                  <Link to={watIs.buttonLink} className="btn btn-transp">
                    {watIs.buttonText}
                    <img
                      src={arrow}
                      alt="arrow"
                      width="18"
                      className="button-img"
                    />
                  </Link>
                </p>
              </div>
              <div className="col-lg-1" />
              <div className="col-lg-4">
                <h2>{watIs.form_title}</h2>
                <ContactForm />
              </div>
              <div className="col-12 empty-space-215" />
            </div>
          </div>
        </section>
        <section className="order-book">
          <div className="container-fluid">
            <div className="row py-4 ">
              <div className="container">
                <div className="col-lg-5">
                  <Img
                    fluid={orderBook.image_book.localFile.childImageSharp.fluid}
                  />
                </div>
                <div
                  className="col-lg-7"
                  dangerouslySetInnerHTML={{ __html: orderBook.text_book }}
                />
              </div>
            </div>
          </div>
          <div className="container last">
            <div className="row">
              <div className="col-lg-5">
                <Link to="/contact" className="btn w-250">
                  {orderBook.button_text_book}
                  <img
                    src={arrow}
                    alt="arrow"
                    width="18"
                    className="button-img"
                  />
                </Link>
              </div>
              <div className="col-lg-7" />
            </div>
          </div>
        </section>
        <section className="over-de-auteur">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <h2 className="title">
                  <img src={arrowWhite} width="18" alt="arrow" />
                  <span>{homeAuthor.title}</span>
                  <small>{homeAuthor.name_author}</small>
                </h2>
                <div dangerouslySetInnerHTML={{ __html: homeAuthor.authorText }} />
                <p className="float-right">
                  <a href={homeAuthor.linkButton} target="_blank" rel="noopener noreferrer" className="btn">
                    {homeAuthor.textButton}
                    <img
                      src={arrow}
                      alt="arrow"
                      width="18"
                      className="button-img"
                    />
                  </a>
                </p>
              </div>
              <div className="col-lg-2" />
              <div className="col-lg-3">
                <img src={jeroen} width="320" alt="Jeroen" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query HomePage {
    wordpressPage(slug: { eq: "home" }) {
      title
      acf {
        header {
          header_image
          header_text_first
          header_text_last
          subheader_button_text
          subheader_text
          subheader_button_link
        }
        home_wat_is_kavelruil {
          form_title
          text
          title
          buttonText: button_text
          buttonLink: button_link
        }
        order_book {
          button_text_book
          image_book {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 466) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          text_book
        }
        about_author {
          author_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 339) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          authorText: author_text
          textButton: button_text
          linkButton: button_link
          name_author
          title
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`
